import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ResetView from '@/views/ResetView.vue'
import CompareView from '@/views/CompareView.vue'
import ReviewView from '@/views/ReviewView.vue'
import MaintenanceView from '@/views/MaintenanceView.vue'
export default [
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true },
    component: LoginView,
  },
  {
    path: '/reset',
    name: 'reset',
    meta: { allowAnonymous: true },
    component: ResetView,
  },
  {
    path: '/',
    name: 'home',
    meta: { layout: 'side-nav' },
    component: HomeView,
  },
  {
    path: '/compare',
    name: 'compare',
    meta: { layout: 'default' },
    component: CompareView,
  },
  {
    path: '/review',
    name: 'Review',
    meta: { layout: 'default' },
    component: ReviewView,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: { layout: 'default' },
    component: MaintenanceView,
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: 'default' },
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
  },
]

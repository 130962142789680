<template>
  <database-maintenance />
</template>
<script>
import DatabaseMaintenance from '@/components/maintenance/database-maintenance.vue'
export default {
  name: 'CompareView',
  components: {
    DatabaseMaintenance,
  },
}
</script>
<style lang="scss" scoped></style>

<template>
  <div>
    <h4>Compare and Update Databases</h4>
  </div>
  <div class="row">
    <div class="col">
      <p>
        Ensure your Source DEV database is current with the Visual Studio
        database project. Use the comparison tool below to present the changes
        and create the update SQL script. After review and when ready, 'Apply'
        this SQL script to similar databases.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- COMPARE -->
      <div class="input-group custom-group">
        <span class="input-group-text">Source</span>
        <Multiselect
          v-model="source"
          class="form-control custom-multiselect"
          placeholder="choose database"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :groups="true"
          :options="groupDevDBs"
          mode="single"
          label="target"
          value-prop="databaseName"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset('source')"
        />
        <span class="input-group-text">Target</span>
        <Multiselect
          v-model="target"
          class="form-control custom-multiselect"
          placeholder="choose database"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :groups="true"
          :options="groupTargetDBs"
          :disabled="!isSource"
          mode="single"
          label="target"
          value-prop="databaseName"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset('target')"
        />
        <button
          class="btn btn-primary"
          :disabled="!selectedCompare"
          @click="compareDBs"
        >
          Compare
        </button>
      </div>
    </div>
    <div v-if="gotCompare" class="col">
      <!-- APPLY TO TARGETS -->
      <div class="input-group">
        <span class="input-group-text">Select Target(s)</span>
        <Multiselect
          v-model="selectedDBs"
          class="form-control custom-multiselect"
          placeholder="choose database(s)"
          :open-direction="'bottom'"
          :closeOnSelect="false"
          :groups="true"
          :options="groupTargetDBs"
          mode="tags"
          label="databaseName"
          value-prop="databaseName"
          :style="{ '--ms-max-height': '30rem' }"
        />
        <button
          class="btn btn-primary"
          :disabled="!gotCompare"
          @click="showFullScript"
        >
          Show Script
        </button>
        <button
          class="btn btn-success custom-separator"
          :disabled="!gotTargets || isApplying"
          @click="confirmApply"
        >
          <FontAwesomeIcon
            v-if="isApplying"
            icon="fa-solid fa-spinner me-2"
            spin
          />
          Apply
        </button>
      </div>
    </div>
    <div v-else class="col"></div>
  </div>
  <div v-if="isComparing" class="row mt-2">
    <!-- ANALYZING (wait) -->
    <div class="col">
      <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin /> Comparing...
    </div>
  </div>
  <div v-else-if="gotCompare" class="row mt-3">
    <!-- ANALYZING -->
    <div class="col">
      <div class="row custom-results">
        <div class="col">
          <span class="custom-label">Tables Added:</span>
          <ul class="custom-list">
            <li v-for="(item, idx) of compare.TablesAdded" :key="idx">
              <a href="#" @click="showScript(item.Text, null)">{{
                item.Name
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col">
          <span class="custom-label">Table(s) Columns Added:</span>
          <ul class="custom-list">
            <li v-for="(item, idx) of compare.TablesColumnsAdded" :key="idx">
              <a href="#" @click="showScript(item.Text, null)">{{
                item.Name
              }}</a
              >:
              <span class="fst-italic"
                >{{ item.Columns.length }} column(s)</span
              >
            </li>
          </ul>
        </div>
        <div class="col">
          <span class="custom-label">Routines Added:</span>
          <ul class="custom-list">
            <li v-for="(item, idx) of compare.RoutinesAdded" :key="idx">
              <span class="fst-italic">{{ item.Type }}</span
              >:
              <a href="#" @click="showScript(item.Text, null)">{{
                item.Name
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col">
          <span class="custom-label">Routines Changed:</span>
          <ul class="custom-list">
            <li v-for="(item, idx) of compare.RoutinesChanged" :key="idx">
              <span class="fst-italic">{{ item.Type }}</span
              >:
              <a href="#" @click="showScript(item.Text, item.CurrentText)">{{
                item.Name
              }}</a>
            </li>
          </ul>
        </div>
        <div class="custom-json">
          <a href="#" @click="isJson = true">json</a>
        </div>
      </div>
      <div v-if="isScript" class="row">
        <!-- Routine -->
        <div v-if="isRoutine" class="col">
          <a href="#" @click="copy('script')">copy</a>
          <code-diff
            :old-string="oldScript"
            :new-string="currentScript"
            :language="'sql'"
            output-format="side-by-side"
            filename="Target"
            new-filename="Source"
          >
          </code-diff>
        </div>
        <!-- Full Script -->
        <div v-else-if="!isEdit" class="col">
          <a href="#" @click="copy('script')">copy</a>
          <a href="#" class="ms-2" @click="edit()">edit</a>
          <code-diff
            :old-string="oldScript"
            :new-string="currentScript"
            :language="'sql'"
            output-format="line-by-line"
          >
          </code-diff>
        </div>
        <!-- Editing Script -->
        <div v-else class="col">
          <a href="#" class="ms-2" @click="cancel()">cancel</a>
          <span v-if="isSave" class="ms-2 text-danger">Script Changed</span>
          <a href="#" class="ms-2" @click="save()">save</a>
          <CodeEditor
            v-model="currentScript"
            :languages="[['sql', 'SQL']]"
            :tab-spaces="4"
            class="custom-editor"
            width="100%"
            theme="github"
            @keyup="isSave = true"
          ></CodeEditor>
        </div>
      </div>
      <!-- View as JSON -->
      <div v-if="isJson" class="row">
        <div class="col">
          <a href="#" class="ms-2" @click="cancelJson()">cancel</a>
          <json-viewer :value="compare" copyable />
        </div>
      </div>
      <div v-if="isError" class="row mt-2">
        <div class="col">
          <div class="alert alert-danger" role="alert">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <confirm-apply
    v-model="actionConfirm"
    :confirm-title="confirmTitle"
    :confirm-action="confirmAction"
    :confirm-question="confirmQuestion"
    @closing="closeModal"
    @proceed="performApply"
  />
</template>
<script>
// eslint-disable-next-line no-unused-vars
import hljs from 'highlight.js'
import { mapState, mapActions } from 'pinia'
import { compareStore } from '@/stores/compare'
import { JsonViewer } from 'vue3-json-viewer'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CodeDiff } from 'v-code-diff'
import CodeEditor from 'simple-code-editor'
import Multiselect from '@vueform/multiselect'
import ConfirmApply from '@/components/utilities/elements/ConfirmDialog.vue'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'CompareDatabase',
  components: {
    JsonViewer,
    FontAwesomeIcon,
    Multiselect,
    CodeDiff,
    ConfirmApply,
    CodeEditor,
  },
  data() {
    return {
      source: null,
      target: null,
      isScript: false,
      isJson: false,
      selectedDBs: [],
      currentScript: null,
      oldScript: null,
      isRoutine: false,
      isEdit: false,
      isSave: false,
      isApplying: false,
      actionConfirm: null,
      error: null,
      isError: false,
      confirmTitle: 'Confirm?',
      confirmAction: 'Apply',
      confirmQuestion:
        'Are you sure you wish to Apply this script to selected databases?',
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(compareStore, ['loading', 'databases', 'compare']),
    isComparing() {
      let _is = false
      if (this.loading.compare) {
        _is = this.loading.compare
      }
      return _is
    },
    gotCompare() {
      let _is = false
      if (this.compare) {
        _is = true
      }
      return _is
    },
    selectedCompare() {
      let _is = false
      if (this.source !== null && this.target !== null) {
        _is = true
      }
      return _is
    },
    gotTargets() {
      let _is = false
      if (this.selectedDBs.length > 0) {
        _is = true
      }
      return _is
    },
    sourceDBs() {
      let _dbs = []
      if (this.databases) {
        _dbs = this.databases.filter(x => x.type === 'DEV')
      }
      return _dbs
    },
    targetDBs() {
      let _dbs = []
      if (this.databases) {
        _dbs = this.databases.filter(x => x.type === 'PROD')
      }
      return _dbs
    },
    groupDevDBs() {
      let _dbs = []
      if (this.databases) {
        let filtered = this.databases.filter(x => x.type === 'DEV')
        // Group data by the 'group' field
        const groupedData = filtered.reduce((acc, item) => {
          const label = item.group

          // If the label doesn't exist in the accumulator, create it
          if (!acc[label]) {
            acc[label] = []
          }

          // Add the database name to the appropriate group
          acc[label].push(item.databaseName)

          return acc
        }, {})
        // Convert grouped data into the desired format
        _dbs = Object.keys(groupedData).map(label => ({
          label,
          options: groupedData[label].sort(),
        }))
      }
      return _dbs
    },
    groupTargetDBs() {
      let _dbs = []
      if (this.databases && this.source) {
        let group = null
        let found = this.databases.find(x => x.databaseName === this.source)
        if (found) {
          group = found.group
          let filtered = this.databases.filter(x => x.group === group)
          // Group data by the 'group' field
          const groupedData = filtered.reduce((acc, item) => {
            const label = item.group

            // If the label doesn't exist in the accumulator, create it
            if (!acc[label]) {
              acc[label] = []
            }

            // Add the database name to the appropriate group
            if (item.databaseName !== this.source) {
              if (this.source.indexOf('AdasMatrix') !== -1) {
                if (item.databaseName.indexOf('AdasMatrix') !== -1) {
                  acc[label].push(item.databaseName)
                }
              } else if (this.source.indexOf('LaunchPad') !== -1) {
                if (item.databaseName.indexOf('LaunchPad') !== -1) {
                  acc[label].push(item.databaseName)
                }
              } else if (this.source.indexOf('ConnectCopilot') !== -1) {
                if (item.databaseName.indexOf('ConnectCopilot') !== -1) {
                  acc[label].push(item.databaseName)
                }
              } else if (this.source.indexOf('ConnectRollup') !== -1) {
                if (item.databaseName.indexOf('ConnectRollup') !== -1) {
                  acc[label].push(item.databaseName)
                }
              } else {
                acc[label].push(item.databaseName)
              }
            }

            return acc
          }, {})
          // Convert grouped data into the desired format
          _dbs = Object.keys(groupedData).map(label => ({
            label,
            options: groupedData[label].sort(),
          }))
        }
      }
      return _dbs
    },
    groupDBs() {
      let _dbs = []
      if (this.databases) {
        // Group data by the 'group' field
        const groupedData = this.databases.reduce((acc, item) => {
          const label = item.group

          // If the label doesn't exist in the accumulator, create it
          if (!acc[label]) {
            acc[label] = []
          }

          // Add the database name to the appropriate group
          acc[label].push(item.databaseName)

          return acc
        }, {})

        // Convert grouped data into the desired format
        _dbs = Object.keys(groupedData).map(label => ({
          label,
          options: groupedData[label].sort(),
        }))
      }
      return _dbs
    },
    isSource() {
      let _is = false
      if (this.source) {
        _is = true
      }
      return _is
    },
    isTarget() {
      let _is = false
      if (this.target) {
        _is = true
      }
      return _is
    },
    script() {
      let _script = ''
      if (this.compare) {
        _script = this.compare.Script
      }
      return _script
    },
    currentText() {
      let _script = ''
      if (this.compare) {
        _script = this.compare.currentText
      }
      return _script
    },
  },
  methods: {
    ...mapActions(compareStore, [
      'resetCompare',
      'getDatabases',
      'getCompare',
      'applyScript',
    ]),
    init() {
      this.reset('all')
      this.getDatabases()
    },
    reset(from) {
      this.isSave = false
      this.isEdit = false
      this.isRoutine = false
      this.isScript = false
      this.isJson = false
      this.isApplying = false
      this.target = null
      this.isError = false
      this.error = null
      if (from === 'all' || from === 'source') {
        this.source = null
      }
      this.selectedDBs = []
      this.currentScript = null
      this.oldScript = null
      this.resetCompare()
    },
    async compareDBs() {
      console.log('Comparing DBs', {
        source: this.source,
        target: this.target,
      })
      this.isSave = false
      this.isEdit = false
      this.isRoutine = false
      this.isScript = false
      this.isJson = false
      this.isApplying = false
      this.currentScript = null
      this.oldScript = null
      this.isError = false
      this.error = null
      await this.getCompare({
        source: this.source,
        target: this.target,
        action: 'analyze',
      })
    },
    closeModal() {
      this.actionConfirm = null
    },
    showFullScript() {
      this.isScript = true
      this.isRoutine = false
      this.oldScript = null
      this.isEdit = false
      this.currentScript = this.compare.Script
    },
    showScript(script, oldScript) {
      this.isEdit = false
      this.isRoutine = false
      this.currentScript = script
      this.oldScript = null
      if (oldScript) {
        this.isRoutine = true
        this.oldScript = oldScript
      }
      this.isScript = true
    },
    copy() {
      navigator.clipboard.writeText(this.currentScript)
      alert('Script copied to clipboard')
    },
    edit() {
      this.isEdit = true
    },
    cancel() {
      this.isEdit = false
      this.isSave = false
      this.currentScript = this.script
    },
    cancelJson() {
      this.isJson = false
    },
    save() {
      this.script = this.currentScript
      this.isEdit = false
      this.isSave = false
    },
    confirmApply() {
      this.actionConfirm = true
    },
    async performApply() {
      this.isError = false
      this.error = null
      let data = {
        script: this.currentScript ? this.currentScript : this.script,
        databases: this.selectedDBs,
      }
      this.actionConfirm = null
      console.log('Applying to Targets', data)
      this.isApplying = true
      let results = await this.applyScript(data)
      this.isApplying = false
      if (results.indexOf('error') !== -1) {
        console.log('Error', results)
        this.isScript = false
        this.error = results
        this.isError = true
      } else {
        alert('Script applied to selected databases')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-group {
  height: 54px;
}
.custom-separator {
  border-left: 2px solid #ffffff;
}
.custom-label {
  font-weight: bold;
}
.multiselect-dropdown {
  --ms-max-height: 20rem;
}
.custom-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
}
.custom-editor {
  border: 1px solid #ced4da;
}
.custom-results {
  position: relative;
}
.custom-json {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  text-align: right;
}
</style>

import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

const userDefault = {
  firm: null,
  user: null,
  emailAddress: null,
  username: null,
  firstName: '',
  lastName: '',
  fullName: '',
  isInactive: false,
  timezone: 5,
  language: 1,
  roles: [],
  guid: null,
  profileUrl: null,
  techId: null,
  contactId: null,
  password: null,
  password2: null,
}

export const userStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(JSON.stringify(userDefault)),
    users: {
      count: 0,
      items: [],
    },
    loading: {
      user: false,
      users: false,
      userApps: false,
    },
    listParams: {
      page: 1,
      pageSize: 10,
      filterOptions: null,
      filterBy: null,
      sortBy: 'asc',
      orderBy: 'user',
      searchTerm: '',
    },
    foundUsers: null,
    userApps: [],
    appUser: {
      emailAddress: null,
      firm: 1,
      firstName: null,
      lastName: null,
      language: 1,
      timezone: 12,
      roles: null,
      username: null,
      apps: [],
    },
  }),
  actions: {
    resetUser(firm) {
      this.user = JSON.parse(JSON.stringify(userDefault))
      if (firm) {
        this.user.firm = firm
      }
    },
    setAppUser(data) {
      switch (data.name) {
        case 'apps': {
          this.appUser.apps.forEach(element => {
            element.checked = false
          })
          data.value.forEach(element => {
            let found = this.appUser.apps.find(x => x.name === element)
            if (found) {
              found.checked = true
            }
          })
          break
        }
        default: {
          this.appUser[data.name] = data.value
          break
        }
      }
    },
    setUser(data) {
      this.user[data.name] = data.value
    },
    setListParam(data) {
      this.listParams[data.name] = data.value
    },
    setLoading(data) {
      this.loading[data.name] = data.value
    },
    setPrimaryFirm(firm) {
      this.userFirms.forEach(element => {
        element.isPrimary = 0
        if (parseInt(element.firm) === parseInt(firm)) {
          element.isPrimary = 1
          element.isSave = true
        }
      })
    },
    setUserFirm(data) {
      this.userFirms.push(data)
    },
    async getUsers(options) {
      this.users = {
        count: 0,
        items: [],
      }
      this.loading.users = true
      this.users = await api.get('user/users', options)
      this.loading.users = false
      console.log('Got Users', this.users)
      return this.users
    },
    async getUser(uid) {
      this.resetUser()
      this.loading.user = true
      this.user = await api.get(`user/profile?username=${uid}`)
      this.loading.user = false
      console.log('Got User', this.user)
      return this.user
    },
    async saveUser() {
      return await api.post('user/saveUser', this.user)
    },
    async verifyUsername(username) {
      return await api.get(`user/verifyUsername?username=${username}`)
    },
    async saveCredentials(data) {
      return await api.post(`user/saveCredentials`, data)
    },
    async getUserApps(user) {
      this.userApps = []
      this.loading.userApps = true
      this.userApps = await api.get(`user/userApps?user=${user}`)
      this.loading.userApps = false
      return this.userApps
    },
  },
})

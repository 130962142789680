import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const reviewStore = defineStore('review', {
  state: () => ({
    loading: {
      databases: false,
      scripts: false,
      review: false,
    },
    databases: [],
    scripts: [],
    review: null,
  }),
  actions: {
    resetReview() {
      this.review = null
    },
    async getDatabases() {
      this.loading.databases = true
      this.databases = []
      let results = await api.get('database/names')
      if (results) {
        this.databases = results
      }
      this.loading.databases = false
      return this.databases
    },
    async getScripts() {
      this.loading.scripts = true
      this.scripts = []
      let results = await api.get('database/scripts')
      if (results) {
        this.scripts = results
      }
      this.loading.scripts = false
      return this.scripts
    },
    async getReview(options) {
      this.loading.review = true
      this.review = null
      let results = await api.get(
        `database/review?database=${options.target}&tool=${options.tool}`,
      )
      if (results) {
        this.review = results
      }
      this.loading.review = false
      return this.review
    },
  },
})

<template>
  <section>
    <z-dialog
      :show="isVisible"
      :modal-size="modalSize"
      :d-style="'z-index: 1070'"
      :bd-style="'z-index: 1065'"
    >
      <template #header>
        <div class="row custom-header">
          <div class="col">
            <h4 class="modal-title">
              {{ title }}
            </h4>
          </div>
          <div class="col-1">
            <a href="#" class="custom-close" @click="close">
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
      </template>
      <template #body>
        <div class="custom-body">
          <div v-html="explanation"></div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col-1">
            <span title="id">{{ id }}</span>
          </div>
          <div class="col">
            <z-message v-model="message" class="alert alert-warning mt-2" />
          </div>
          <div class="col-4 custom-right-align">
            <button type="button" class="btn btn-light" @click="close()">
              Cancel
            </button>
          </div>
        </div>
      </template>
    </z-dialog>
  </section>
</template>
<script>
import { mapState } from 'pinia'
import { reviewStore } from '@/stores/review'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'
export default {
  name: 'DialogPreviewLink',
  components: {
    ZDialog,
    ZMessage,
  },
  props: {
    modelValue: Object,
  },
  emits: ['closing'],
  data() {
    return {
      modalSize: 'modal-lg',
      message: null,
    }
  },
  computed: {
    ...mapState(reviewStore, ['scripts']),
    isVisible() {
      return this.modelValue !== null
    },
    title() {
      let _title = 'Explanation'
      if (this.modelValue) {
        _title = `Explanation for ${this.modelValue.scriptName}`
      }
      return _title
    },
    explanation() {
      let _data = null
      if (this.scripts) {
        let found = this.scripts.find(
          script => script.scriptName === this.modelValue.scriptName,
        )
        if (found) {
          _data = found.explanation
        }
      }
      return _data
    },
  },
  methods: {
    close() {
      this.$emit('closing')
    },
    showMessage(message) {
      this.message = message
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-close {
  float: right;
  color: #000;
  cursor: pointer;
}
.custom-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-right-align {
  text-align: right;
}
.hostWrapper {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<template>
  <ValidationObserver ref="form">
    <form v-if="!emailSent" @submit.prevent="submit">
      <z-input
        v-model="username"
        type="text"
        name="username"
        label="Username"
        :validator="v$.username"
      />
      <z-input
        v-model="password"
        type="password"
        name="password"
        label="Password"
        :validator="v$.password"
      />
      <div class="custom-button">
        <button
          type="submit"
          class="btn btn-primary btn-block"
          :disabled="isLoading"
        >
          <div class="custom-button-text">
            <span>Log In</span>
            <div v-show="isLoading" class="ms-2">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </div>
          </div>
        </button>
      </div>
      <div class="custom-button">
        <button
          v-if="isFailedLogin"
          type="submit"
          class="btn btn-primary btn-block mt-2"
          :disabled="isLoading"
          @click="passwordReset"
        >
          Forgot my Password
        </button>
      </div>
      <z-message v-model="message" class="alert alert-warning mt-2" />
    </form>
    <div v-else class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <p>
              No problem, a "Reset Password" link has been sent to your
              registered email address.
            </p>
            <p>
              Please look for that email and click the link to reset your
              passord. You may need to check your spam folder.
            </p>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { authStore } from '@/stores/auth'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'

export default {
  name: 'FormLogin',
  components: {
    ZInput,
    ZMessage,
  },
  data() {
    return {
      message: '',
      rules: {
        username: { required },
        password: { required },
      },
      v$: null,
      isLoading: false,
      isFailedLogin: false,
      emailSent: false,
    }
  },
  beforeMount() {
    this.v$ = useVuelidate(this.rules, this.profile)
    this.isLoading = false
  },
  computed: {
    ...mapState(authStore, ['profile', 'token', 'passthrough']),
    isAdmin() {
      let _is = false
      if ([1, 2, 3, 5].includes(this.profile.roles[0])) {
        _is = true
      }
      return _is
    },
    username: {
      get() {
        let _data = null
        if (this.profile) {
          _data = this.profile.username
        }
        return _data
      },
      set(value) {
        this.setData({
          name: 'username',
          value: value,
        })
      },
    },
    password: {
      get() {
        let _data = null
        if (this.profile) {
          _data = this.profile.password
        }
        return _data
      },
      set(value) {
        this.setData({
          name: 'password',
          value: value,
        })
      },
    },
  },
  methods: {
    ...mapActions(authStore, ['login', 'setData', 'resetPassword']),
    loginAgain() {
      this.isLoading = false
      this.isFailedLogin = false
      this.emailSent = false
    },
    async passwordReset() {
      this.emailSent = false
      if (this.username.trim() !== '') {
        this.emailSent = true
        let result = await this.resetPassword({
          username: this.username.trim(),
        })
        if (result) {
          if (result.success) {
            // let page = `/reset?username=${result.username}&user=${result.user}&contact=${result.contact}&firstname=${result.firstname}&lastname=${result.lastname}`
            // this.$router.push(page)
          } else {
            this.emailSent = false
            this.isLoading = false
            this.message = ''
            this.isFailedLogin = false
            alert(
              'Failed to generate a password reset, please contact support.',
            )
          }
        } else {
          this.emailSent = false
          this.isLoading = false
          this.message = ''
          this.isFailedLogin = false
          alert('An error occurred, please contact support.')
        }
      } else {
        this.emailSent = false
        this.isLoading = false
        this.message = ''
        this.isFailedLogin = false
        this.message = 'Please supply your username/email.'
      }
    },
    async submit() {
      try {
        this.message = ''
        const valid = await this.v$.$validate()
        if (valid) {
          this.isLoading = true
          await this.login(this.username, this.password)
          if (this.token !== '') {
            console.log('logged in...')
          }
          this.isLoading = false
          let page = '/'
          this.$router.push(page)
        } else {
          console.log('Login Failed')
        }
      } catch (e) {
        this.isLoading = false
        this.isFailedLogin = true
        // TODO: This error message should come from the API
        // TODO: Use of toast
        console.error(e)
        this.message = 'Error: Unable to login'
      }
    },
  },
}
</script>
<style lang="scss">
.custom-button {
  width: 100%;
  text-align: center;
}
.custom-button-text {
  display: inline-flex;
}
</style>

<template>
  <review-database />
</template>
<script>
import ReviewDatabase from '@/components/review/review-database.vue'
export default {
  name: 'ReviewView',
  components: {
    ReviewDatabase,
  },
}
</script>
<style lang="scss" scoped></style>

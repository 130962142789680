<template>
  <div v-if="isVisible">
    <div class="modal show custom-confirm" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ confirmTitle }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <p v-html="confirmQuestion"></p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="close()">
              {{ cancelAction }}
            </button>
            <button type="submit" class="btn btn-primary" @click="proceed()">
              {{ confirmAction }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show custom-confirm-back"></div>
  </div>
</template>
<script>
export default {
  name: 'ZDialogConfirm',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    confirmTitle: String,
    confirmAction: String,
    confirmQuestion: String,
    cancelAction: {
      type: String,
      default: 'Cancel'
    }
  },
  emits: ['proceed', 'closing'],
  computed: {
    isVisible: {
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    close() {
      this.$emit('closing')
    },
    proceed() {
      this.$emit('proceed', this.modelValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-confirm {
  display: block;
  z-index: 1085;
}
.custom-confirm-back {
  z-index: 1080;
}
</style>

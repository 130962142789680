import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const compareStore = defineStore('compare', {
  state: () => ({
    loading: {
      compare: false,
      databases: false,
    },
    databases: [],
    compare: null,
  }),
  actions: {
    resetCompare() {
      this.compare = null
    },
    async getDatabases() {
      this.loading.databases = true
      this.databases = []
      let results = await api.get('database/names')
      if (results) {
        this.databases = results
      }
      this.loading.databases = false
      return this.databases
    },
    async getCompare(options) {
      this.loading.compare = true
      this.compare = null
      let results = await api.post('database/compare', options)
      if (results) {
        this.compare = results
      }
      this.loading.compare = false
      return this.compare
    },
    async applyScript(data) {
      return await api.post('database/apply', data)
    },
  },
})

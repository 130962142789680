<template>
  <div>
    <h4>Review Databases</h4>
  </div>
  <div class="row">
    <div class="col">
      <p>
        Use the following tools to examine the selected database to assist with
        performance and maintenance.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- REVIEW -->
      <div class="input-group custom-group">
        <span class="input-group-text">Tool</span>
        <Multiselect
          v-model="tool"
          class="form-control custom-multiselect"
          placeholder="choose tool"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :options="tools"
          mode="single"
          label="label"
          value-prop="value"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset()"
        />
        <span class="input-group-text">Database</span>
        <Multiselect
          v-model="target"
          class="form-control custom-multiselect"
          placeholder="choose database"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :groups="true"
          :options="groupDevDBs"
          mode="single"
          value-prop="databaseName"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset()"
        />
        <button
          class="btn btn-primary"
          :disabled="!selectedReview || gettingReview"
          @click="processReview"
        >
          <FontAwesomeIcon
            v-if="gettingReview"
            icon="fa-solid fa-spinner me-2"
            spin
          />
          <span>Review</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-1">
      <button
        v-if="gotTool"
        class="btn btn-light btn-sm"
        @click="explanation()"
      >
        Explanation
      </button>
    </div>
    <div class="col">
      <p class="custom-description">{{ description }}</p>
    </div>
  </div>
  <div v-if="gettingReview" class="row mt-2">
    <!-- REVIEWING (wait) -->
    <div class="col">
      <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin /> Reviewing...
    </div>
  </div>
  <div v-if="gotReview" class="row mt-2">
    <div class="col">
      <table class="min-w-full table table-bordered table-striped">
        <!-- Dynamic Table Headers -->
        <thead class="bg-white table-sticky">
          <tr>
            <th v-for="(header, index) in headers" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <!-- Dynamic Table Rows -->
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, rowIndex) in review" :key="rowIndex">
            <td v-for="(header, index) in headers" :key="index">
              {{ row[header] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <dialog-explanation v-model="actionExplanation" @closing="closeModal" />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { reviewStore } from '@/stores/review'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DialogExplanation from './dialog-explanation.vue'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'ReviewDatabase',
  components: {
    FontAwesomeIcon,
    Multiselect,
    DialogExplanation,
  },
  data() {
    return {
      tool: null,
      target: null,
      selectedDBs: [],
      error: null,
      isError: false,
      actionExplanation: null,
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(reviewStore, ['loading', 'databases', 'scripts', 'review']),
    selectedReview() {
      let _is = false
      if (this.tool !== null && this.target !== null) {
        _is = true
      }
      return _is
    },
    gettingReview() {
      let _is = false
      if (this.loading.review) {
        _is = true
      }
      return _is
    },
    gotTool() {
      let _is = false
      if (this.tool) {
        _is = true
      }
      return _is
    },
    gotReview() {
      let _is = false
      if (this.review) {
        _is = true
      }
      return _is
    },
    tools() {
      let _tools = []
      if (this.scripts) {
        this.scripts.forEach(element => {
          _tools.push({
            label: element.scriptName,
            value: element.scriptName,
          })
        })
        _tools = _tools.sort()
      }
      return _tools
    },
    groupDevDBs() {
      let _dbs = []
      if (this.databases) {
        // Group data by the 'group' field
        const groupedData = this.databases.reduce((acc, item) => {
          const label = item.group

          // If the label doesn't exist in the accumulator, create it
          if (!acc[label]) {
            acc[label] = []
          }

          // Add the database name to the appropriate group
          acc[label].push(item.databaseName)

          return acc
        }, {})
        // Convert grouped data into the desired format
        _dbs = Object.keys(groupedData).map(label => ({
          label,
          options: groupedData[label].sort(),
        }))
      }
      return _dbs
    },
    headers() {
      if (this.review) {
        if (this.review.length > 0) {
          return Object.keys(this.review[0])
        }
      }
      return []
    },
    description() {
      let _desc = ''
      if (this.tool) {
        let found = this.scripts.find(
          element => element.scriptName === this.tool,
        )
        if (found) {
          _desc = found.description
        }
      }
      return _desc
    },
  },
  methods: {
    ...mapActions(reviewStore, [
      'resetReview',
      'getDatabases',
      'getScripts',
      'getReview',
    ]),
    init() {
      this.reset()
      this.resetReview()
      this.getDatabases()
      this.getScripts()
    },
    reset() {
      this.tool = null
      this.target = null
      this.isError = false
      this.error = null
    },
    closeModal() {
      this.actionExplanation = null
    },
    explanation() {
      this.actionExplanation = {
        scriptName: this.tool,
      }
    },
    async processReview() {
      let data = {
        tool: this.tool,
        target: this.target,
      }
      console.log('Reviewing...', data)
      await this.getReview(data)
    },
  },
}
</script>
<style scoped>
.custom-description {
  padding-top: 0.3rem;
  font-size: 0.9rem;
  font-style: italic;
}
</style>

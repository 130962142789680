<template>
  <div>
    <h4>Database Maintenace</h4>
  </div>
  <div class="row">
    <div class="col">
      <p>Use the following maintenance tools on the selected database.</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- REVIEW -->
      <div class="input-group custom-group">
        <span class="input-group-text">Tool</span>
        <Multiselect
          v-model="tool"
          class="form-control custom-multiselect"
          placeholder="choose tool"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :options="tools"
          mode="single"
          label="label"
          value-prop="value"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset()"
        />
        <span class="input-group-text">Database</span>
        <Multiselect
          v-model="target"
          class="form-control custom-multiselect"
          placeholder="choose database"
          :open-direction="'bottom'"
          :closeOnSelect="true"
          :groups="true"
          :options="groupDevDBs"
          mode="single"
          value-prop="databaseName"
          :style="{ '--ms-max-height': '30rem' }"
          @clear="reset()"
        />
        <button
          class="btn btn-primary"
          :disabled="!selectedMaintenance || runningMaintenance"
          @click="processReview"
        >
          <FontAwesomeIcon
            v-if="runningMaintenance"
            icon="fa-solid fa-spinner me-2"
            spin
          />
          <span>Run</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p class="custom-description">{{ description }}</p>
    </div>
  </div>
  <div v-if="gettingMaintenance" class="row mt-2">
    <!-- RUNNING (wait) -->
    <div class="col">
      <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin /> Running
      Maintenance...
    </div>
  </div>
  <div v-if="gotMainenance" class="row mt-2">
    <z-textarea v-model="maintenance" rows="20" label="" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { maintenanceStore } from '@/stores/maintenance'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Multiselect from '@vueform/multiselect'
import ZTextarea from '@/components/utilities/elements/TextArea.vue'
export default {
  name: 'ReviewDatabase',
  components: {
    FontAwesomeIcon,
    Multiselect,
    ZTextarea,
  },
  data() {
    return {
      tool: null,
      target: null,
      selectedDBs: [],
      error: null,
      isError: false,
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(maintenanceStore, ['loading', 'databases', 'maintenance']),
    selectedMaintenance() {
      let _is = false
      if (this.tool !== null && this.target !== null) {
        _is = true
      }
      return _is
    },
    runningMaintenance() {
      let _is = false
      if (this.loading.maintenance) {
        _is = true
      }
      return _is
    },
    gotMainenance() {
      let _is = false
      if (this.maintenance) {
        _is = true
      }
      return _is
    },
    tools() {
      let _tools = [
        {
          label: 'Azure Database Maintenance',
          value: 'runMaintenance',
        },
      ]
      return _tools
    },
    groupDevDBs() {
      let _dbs = []
      if (this.databases) {
        // Group data by the 'group' field
        const groupedData = this.databases.reduce((acc, item) => {
          const label = item.group

          // If the label doesn't exist in the accumulator, create it
          if (!acc[label]) {
            acc[label] = []
          }

          // Add the database name to the appropriate group
          acc[label].push(item.databaseName)

          return acc
        }, {})
        // Convert grouped data into the desired format
        _dbs = Object.keys(groupedData).map(label => ({
          label,
          options: groupedData[label].sort(),
        }))
      }
      return _dbs
    },
    headers() {
      if (this.review) {
        if (this.review.length > 0) {
          return Object.keys(this.review[0])
        }
      }
      return []
    },
    description() {
      let _desc = null
      if (this.tool === 'runMaintenance') {
        _desc =
          'Azure SQL Maintenance - Maintenance script for Azure SQL Database. NOTE: be sure this stored procedure exists in the database chosen. It can also be a long-running process.'
      }
      return _desc
    },
  },
  methods: {
    ...mapActions(maintenanceStore, [
      'resetMaintenance',
      'getDatabases',
      'runMaintenance',
    ]),
    init() {
      this.reset()
      this.resetMaintenance()
      this.getDatabases()
    },
    reset() {
      this.tool = null
      this.target = null
      this.isError = false
      this.error = null
    },
    async processReview() {
      let data = {
        tool: this.tool,
        target: this.target,
      }
      console.log('Running Maintenance...', data)
      let results = await this.runMaintenance(data)
      console.log('Maintenance Results', results)
    },
  },
}
</script>
<style scoped>
.custom-description {
  font-size: 0.9rem;
  font-style: italic;
}
</style>

<template>
  <compare-database />
</template>
<script>
import CompareDatabase from '@/components/compare/compare-database.vue'
export default {
  name: 'CompareView',
  components: {
    CompareDatabase,
  },
}
</script>
<style lang="scss" scoped></style>

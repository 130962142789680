<template>
  <div>
    <h4>Welcome</h4>
  </div>
  <div class="row">
    <div class="col">
      <p>
        The purpose of DB Copilot is for Developers to compare various OPUS IVS
        partner databases and perform maintenance activities.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
</template>
<script>
export default {
  name: 'HomePage',
}
</script>
<style lang="scss" scoped></style>

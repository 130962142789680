import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const maintenanceStore = defineStore('maintenance', {
  state: () => ({
    loading: {
      databases: false,
      maintenance: false,
    },
    databases: [],
    maintenance: null,
  }),
  actions: {
    resetMaintenance() {
      this.maintenance = null
    },
    async getDatabases() {
      this.loading.databases = true
      this.databases = []
      let results = await api.get('database/names')
      if (results) {
        this.databases = results
      }
      this.loading.databases = false
      return this.databases
    },
    async runMaintenance(options) {
      this.loading.maintenance = true
      this.maintenance = null
      let results = await api.get(
        `database/${options.tool}?database=${options.target}`,
      )
      if (results) {
        this.maintenance = results
      }
      this.loading.maintenance = false
      console.log('Got Maintenance:', this.maintenance)
      return this.maintenance
    },
  },
})
